@use '@angular/material' as mat;
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$abcdj-primary: mat.define-palette($abcdj-palette);
$abcdj-accent: mat.define-palette($abcdj-accent-palette);

// The warn palette is optional (defaults to red).
$abcdj-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$abcdj-theme: mat.define-light-theme(( //
      color: ( //
        primary: $abcdj-primary,
        accent: $abcdj-accent,
        warn: $abcdj-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($abcdj-theme);


@include mat.core();

/** migration to v15 */
$primary-palette: mat.define-palette($abcdj-palette);
$accent-palette: mat.define-palette($abcdj-accent-palette);
$theme: mat.define-light-theme(( //
      // set theme color scheme
      color: ( //
        primary: $primary-palette,
        accent: $accent-palette,
        warn: $abcdj-warn,
      ),
      // Use the default typography configuration.
      typography: mat.define-typography-config(),
      density: -1,
    ));

@include mat.core-theme($theme);
@include mat.all-component-themes($theme);


/** component specific styles */

/** SNACKBAR */
.mat-mdc-snack-bar-container {
  &.info-alert {
    --mdc-snackbar-container-color: #e2e3e5;
    --mdc-snackbar-supporting-text-color: #383d41;
    --mat-mdc-snack-bar-button-color: #383d41;
  }

  &.success-alert {
    --mdc-snackbar-container-color: #d4edda;
    --mdc-snackbar-supporting-text-color: #155724;
    --mat-mdc-snack-bar-button-color: #155724;
  }

  &.warning-alert {
    --mdc-snackbar-container-color: #fff3cd;
    --mdc-snackbar-supporting-text-color: #856404;
    --mat-mdc-snack-bar-button-color: #856404;
  }

  &.error-alert {
    --mdc-snackbar-container-color: #f8d7da;
    --mdc-snackbar-supporting-text-color: #721c24;
    --mat-mdc-snack-bar-button-color: #721c24;
  }
}

/** BUTTON */
.mdc-button {
  // restore letter spacing from v14 as 1.25rem looks ugly
  --mdc-typography-button-letter-spacing: normal;
}

// replicate our fancy rounded buttons
.mat-mdc-button-base {
  &.abcdj-button {
    border-radius: 18px;
  }
}

// stupid material does not use the 500 contrast color as specified in the palette
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #fff;
}

/** TABS */
.mat-mdc-tab-header {
  // restore thin grey border under the tabs from v14
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/** FORM FIELD */
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  // the grey background is fancy for the currently selected form, the others should be transparent (white usually)
  background-color: inherit;
}


/** SELECTION LIST */
.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: 8px;
}

/** mat-menu */
// not working :( and since default it's 280, menu item for aws-comprehend is truncated
.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 320px;
}
