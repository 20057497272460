@import "spacing";

/**** overriding of the tabs styles to fitting the tab content height with its container ***/
.mat-tab-body-wrapper,
.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-group,
.mat-mdc-tab-group {
  height: 100%;
}

.mat-tab-body,
.mat-mdc-tab-body {
  flex-grow: 1;
}

/************************************************************************/

//resize tabs
.mat-tab-group .mat-tab-label {
  height: 42px;
  padding: 0 12px;
  min-width: 110px;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $abcdj-main-color;
}

.mat-form-field-infix {
  .mat-select-arrow-wrapper {
    vertical-align: unset;
  }
}

/*************************** LAYOUT ********************************/
.padded-content-xs {
  padding: 0 $spacing-xs;
}

.padded-content,
.padded-content-s {
  padding: 0 $spacing-s;
}

/***************************  BUTTONS ********************************/
//small rounded buttons
.abcdj-button.mat-flat-button {
  border-radius: 16px;
  font-weight: 100;
  font-size: 13px;
  margin: 0px 5px;
  height: 32px;
  line-height: 30px;
}

.abcdj-button.mat-icon-button {
  width: 32px;
  height: 32px;
  line-height: 32px;

  .mat-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  .material-icons {
    font-size: 20px;
  }
}

a.mat-icon-button {

  &:hover,
  &:focus {
    text-decoration: none;
  }
}


// TODO define reasonable values
.small-button {
  &.mat-icon-button {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;

    .mat-icon {
      width: 16px !important;
      height: 16px !important;
      line-height: 16px !important;
    }

    .material-icons {
      font-size: 16px !important;
    }
  }
}

.mat-icon-inline {
  vertical-align: middle;
}

/**************************** INPUT ********************************/
mat-form-field {
  &.abcdj-mat-form-field {
    width: 100%;

    .mat-form-field-infix {
      width: 100%;
    }
  }

  .abcdj-mat-form-field-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -15px;

    input.mat-input-element {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  input.mat-input-element {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }

  .mat-select {
    font-weight: 400;
  }
}

div .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
  background-image: none;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-weight: 400;
}

/******************************* DIALOG / MODAL ****************************************/
.hdis-dialog-container {

  .mat-mdc-dialog-container {

    .close-drawer-button {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .mat-mdc-dialog-title {
      text-align: center;
      text-transform: uppercase;
    }

    .mat-mdc-dialog-actions {
      padding: 0 24px 20px 24px;
    }
  }

}


/******************************* DRAWER ****************************************/
.mat-drawer-container {
  height: 100%;
  // width: 100%;
  background-color: #fff;

  .mat-drawer-side {
    border-right: 1px solid #e7e7e7;
  }

  .mat-drawer-content {
    background-color: #fff;
  }
}

/******************************* SNACKBAR ****************************************/
// color palette borrowed from bootstrap alerts
/** @deprecated .mat-snack-bar-container remove after migration to v15 is completed */
.mat-snack-bar-container,
.hdis-alert-message {
  &.warning-alert {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;

    .mat-simple-snackbar-action {
      color: #856404;
    }
  }

  &.error-alert {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

    .mat-simple-snackbar-action {
      color: #721c24;
    }
  }

  &.ok-alert {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;

    .mat-simple-snackbar-action {
      color: #155724;
    }
  }

  &.info-alert {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;

    .mat-simple-snackbar-action {
      color: #383d41;
    }
  }
}

.hdis-alert-message {
  padding: 5px;
}

// For Google Chrome
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  height: 40px; // min height for very looong tables
  background: #ccc;
}

::-webkit-scrollbar-track {
  background: transparent;
}

// For Internet Explorer
body {
  scrollbar-face-color: #ccc;
  scrollbar-track-color: transparent;
}
