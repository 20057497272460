// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import 'libs/hdis-ui/src/lib/styles/variables';
@import 'custom-material/mlm-identity';
@import 'custom-material/custom-theme';

@import "~@ag-grid-community/styles/ag-grid.css";
@import "~@ag-grid-community/styles/ag-theme-material.css";

@import 'utils';

.ag-theme-material {
  --ag-material-primary-color: #{$abcdj-main-color};
  --ag-material-accent-color: #{$abcdj-main-color};
  --ag-grid-size: 5px;
  --ag-header-column-separator-display: 'block';
  --ag-header-column-resize-handle-display: 'block';
  --ag-odd-row-background-color: #fcfcfc; // zebra rows
  --ag-row-hover-color: #ecf9ed;
  --ag-selected-row-background-color: #{$abcdj-background-color};
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #{$abcdj-main-color};
  --amplify-components-button-primary-hover-background-color: #ff8578;
  --amplify-components-button-primary-active-background-color: #ff6858;

  --amplify-components-button-hover-background-color: #{abcdj-secondary-color};
  --amplify-components-button-hover-border-color: #{$abcdj-main-color};
  --amplify-components-button-hover-color: #333;

  --amplify-components-button-active-background-color: #{abcdj-secondary-color};
  --amplify-components-button-active-border-color: #{$abcdj-main-color};
  --amplify-components-button-active-color: #333;

  --amplify-components-button-link-color: #333;
  --amplify-components-button-link-border-width: 0;

  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-hover-color: #{$abcdj-main-color};

  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-focus-color: #{$abcdj-main-color};
  --amplify-components-button-link-focus-box-shadow: 0px;

  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-active-color: #{$abcdj-main-color};
}

[data-amplify-authenticator] [data-amplify-footer] {
  padding-left: var(--amplify-components-authenticator-form-padding);
  padding-right: var(--amplify-components-authenticator-form-padding);
  padding-bottom: var(--amplify-components-authenticator-footer-padding-bottom);
}

@import '~@aws-amplify/ui-angular/theme.css';
@import '~quill/dist/quill.snow.css';


// [data-amplify-authenticator] [data-amplify-footer] {
//   padding: var(--amplify-components-authenticator-form-padding);
// }



// .amplify-button[data-variation='primary'] {
//   $color-primary: mat.get-color-from-palette(map-get($abcdj-theme, primary));
//   background: $color-primary
// }

amplify-authenticator {
  --amplify-primary-color: $abcdj-accent-color;
  --amplify-primary-tint: $abcdj-accent-color;
  --amplify-primary-shade: $abcdj-accent-color;
}


html {
  height: 100%;
  background-color: white;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

// this makes no sense. Why should we make b and strong look like regular text?
// commented out on 13/10/22 and leave for a bit as this is potentially very impactful, but should be removed at some point
// b,
// strong {
//   font-weight: 500;
// }

// td b,
// td strong {
//   font-weight: bold;
// }

.logo {
  padding: 15px;
  margin: 0px;
  background-color: #f7f7f7;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  border-width: 1px;
  width: 250px;
}

.nav>li.disabled>a,
a.nav-link.disabled {
  pointer-events: none;
  cursor: default;
  color: #aaaaaa;
}

a.disabled {
  pointer-events: none;
  cursor: no-drop;
  color: #ccc;
}

.centering {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.login-panel {
  margin-top: 80px;
  vertical-align: middle;
}

@import 'sizes/sm';
@import 'sizes/xs';

a:hover,
a:focus {
  color: #444444;
  text-decoration: underline;
}

a {
  color: #666666;
  text-decoration: none;
}

.small-circle {
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

th {
  cursor: pointer;
}

th.alert-col {
  cursor: default;
}

th:focus {
  outline: none;
}

.line-height {
  line-height: 40px;
  font-weight: normal;
}

.container-fluid {
  .jumbotron {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
  }
}

span.spacer {
  padding: 5px;
}

.bottom-border {
  border-bottom: 1px solid #ddd;
}

.gray {
  color: gray;
}

.lightgray {
  color: #bbbbbb;
}

.checkbox {
  height: 40px;
  line-height: 18px;
  padding-top: 0;

  label {
    padding-top: 11px;
    /* (height - line-height) / 2 */
    padding-right: 26px;
  }

  input[type='checkbox'] {
    position: absolute;
    left: 0;
  }
}

.row.set-height {
  height: 100%;
  display: flex;
}

.form-group {
  margin-bottom: 5px;
}


/* UTILITY */
/**
 * angular management of disabled fields in form is stupidly limit (e.g. if a given form should stay disabled you cant just en/disable the whole form without losing the invididual field status)
 * so we define a wrapper fieldset that is toggled on some condition and can disable any descendant form element (input, buttons etc), even in nested components
 * then we need these styles to reset the fieldset look from the browser default styles
 */
.disable-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}


.flex-column {
  @include flex-column()
}

.flex-row {
  @include flex-row()
}

.flex-fill {
  @include flex-fill()
}

.flex-auto {
  @include flex-auto()
}

.flex-none {
  @include flex-none()
}

.flex-one {
  @include flex-one()
}

.flex-two {
  @include flex-two()
}

.flex-items-start {
  @include flex-items-start()
}

.flex-items-center {
  @include flex-items-center()
}

.flex-items-end {
  @include flex-items-end()
}

.flex-items-baseline {
  @include flex-items-baseline()
}


.flex-content-start {
  @include flex-content-start()
}

.flex-content-center {
  @include flex-content-center()
}

.flex-content-end {
  @include flex-content-end()
}

.flex-content-between {
  @include flex-content-between()
}

.flex-content-around {
  @include flex-content-around()
}

.flex-content-even {
  @include flex-content-even()
}



.bg-white {
  background-color: white;
}

.bg-gray {
  background-color: #f7f7f7;
}

.bg-dark-gray {
  background-color: #95989a;
}

.bg-lightgray {
  // DEPRECATED (gray dovrebbe diventare light-gray e questo gray)
  background: #eeeeee;
}

.padding-tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.margin-10 {
  margin: 10px;
}

.margin-tb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

/*
reset stili browser e boostrap
TODO refactor bootstrap-theme
*/
input,
.form-control {
  box-shadow: none;
}

input:focus,
input:active,
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
.form-control:focus {
  background: none;
  background-color: none;
  box-shadow: none;
}

/*
TODO refactor bootstrap theme
*/
hr {
  border-top: 1px solid #ddd;
}

hr.no-bottom {
  margin-bottom: 0;
}

hr.no-top {
  margin-top: 0;
}

.not-visible {
  visibility: hidden;
}

.abc-dj-search {
  .input-group-addon {
    border: 0;
    background: none;
    padding-right: 0;
    padding-left: 0;
    font-size: 14px;
  }

  .form-control {
    border: 0;
    font-size: 14px;
    padding-left: 10px;
  }
}

.brand-logo {
  width: 150px;
  height: 150px;
  text-align: center;
  border: 1px solid #eeeeee;

  img {
    max-width: 100px;
    height: 100%;
  }
}

.button--row-alignment {
  padding: 5px;
}

.input--background-transparent {
  background-color: transparent;
  border: none;
}

.autorenew-icon.mat-icon-button {
  margin-top: 14px;
}

/****** end progress bar ******/

#abcdj-search {
  .mat-form-field-underline {
    height: 2px;
  }
}

@import 'libs/hdis-ui/src/lib/styles/hdis-styles';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.scrollable-container {
  overflow: hidden;
}

.scrollable-content {
  overflow-y: auto;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/***************************/
/* ABCDJ SHARED COMPONENTS */
/***************************/
.sidebar {
  height: 100%;

  .active-link {
    color: $abcdj-main-color;

    // keep interaction buttons (such as remove bookmark) regular color
    .mat-icon-button {
      color: $dark-text;
    }
  }

  .scrollable-content {
    overflow: auto;
    min-height: 2em;
  }

  .fixed-footer {
    text-align: center;

    .mat-stroked-button {
      background-color: rgb(255, 255, 255);
      border-radius: 30px;
      width: 90%;
      margin: 10px 0;
    }
  }

  .sidebar-actions .mat-button-base,
  .sidebar-sections .mat-list-item {
    text-transform: uppercase;
  }

  .sidebar-actions .mat-button-base {
    margin: 2px 0;

  }
}

/***********************/
/* ENTITY DETAILS PAGE */
/***********************/
mat-icon.entity-header-logo-icon {
  width: 136px;
  height: 136px;
  font-size: 136px;
  // color: #e7e7e7;
  background: #f7f7f7;
  color: $abcdj-accent-color;
  // background: $abcdj-accent-background-color;
  display: block;
}

.entity-info {

  .entity-breadcrumbs,
  .entity-title,
  .entity-controls {
    padding: 0;
    margin: 10px 0;
  }

  .entity-breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .entity-title {
    small {
      font-size: 1.2rem;
    }
  }

  .entity-controls {
    .abcdj-button.mat-flat-button {
      padding: 0;
      min-width: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.track-form {

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
  .hdis-enum-picker-form-field.disabled .mat-input-element:disabled,
  .hdis-bpm-field .mat-form-field.disabled .mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.80);
  }

  .mat-form-field.ng-invalid,
  hdis-bpm-field.ng-invalid,
  hdis-value-selector.ng-invalid {
    .mat-input-element {
      color: red;
    }

    .mat-form-field-label {
      color: red !important;

      .mat-form-field-required-marker {
        color: red;
      }
    }

    .mat-form-field-ripple {
      background-color: red;
    }
  }
}

// enable multiline (via \n) on mat-tooltip text
.mat-tooltip-multiline {
  white-space: pre-line;
}

.error-page {
  position: relative;
  height: 100vh;

  .error-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;

    .error-message {
      position: relative;
      height: 200px;
      margin: 0 auto 20px;
      z-index: -1;

      h1 {
        font-size: 236px;
        font-weight: 200;
        margin: 0;
        color: #211b19;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      h2 {
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
        color: #211b19;
        background: #fff;
        padding: 10px 5px;
        margin: auto;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .abcdj-button.mat-flat-button {
      border-radius: 20px;
      font-size: 20px;
      height: 40px;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading-button:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
