@media (min-width: 768px) {
  body {
    height: 100%;
    background-color: white;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
  }

  .app-content {
    overflow: hidden;
  }

  .app-footer {
    display: none;
    overflow: hidden;

    &.is-visible {
      display: block;
    }
  }

  .left-padding {
    padding-left: 50px;
  }

  .btn-breadcrumb {
    margin-top: 7px;
  }


  .top-distance {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .nav>li {
    margin-right: 30px;
  }
}
