@mixin flex-column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

@mixin flex-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

@mixin flex-auto {
  flex: 1 1 auto;
}

@mixin flex-none {
  flex: 0 0 auto;
}

@mixin flex-one {
  flex: 1 1 0;
  width: 0
}

@mixin flex-two {
  flex: 2 2 0;
  width: 0
}

@mixin flex-items-start {
  align-items: flex-start;
}

@mixin flex-items-center {
  align-items: center;
}

@mixin flex-items-end {
  align-items: flex-end;
}

@mixin flex-items-baseline {
  align-items: baseline;
}

@mixin flex-content-start {
  justify-content: flex-start;
}

@mixin flex-content-center {
  justify-content: center;
}

@mixin flex-content-end {
  justify-content: flex-end;
}

@mixin flex-content-between {
  justify-content: space-between;
}

@mixin flex-content-around {
  justify-content: space-around;
}

@mixin flex-content-even {
  justify-content: space-evenly;
}
