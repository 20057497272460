// palette generated by http://mcg.mbitson.com/#!?abcdjpalette=%23ce7dbc

$abcdj-palette: (
  50 : #ffeeec,
  100 : #ffd4cf,
  200 : #ffb8b0,
  300 : #ff9b90,
  400 : #ff8578,
  500 : #ff7060,
  600 : #ff6858,
  700 : #ff5d4e,
  800 : #ff5344,
  900 : #ff4133,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffe0de,
  A700 : #ffc8c4,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$heardis-palette: (
  50 : #e0ffed,
  100 : #b3ffd1,
  200 : #80ffb3,
  300 : #4dff94,
  400 : #26ff7d,
  500 : #00fc44,
  600 : #00ea3b,
  700 : #00d330,
  800 : #00be25,
  900 : #009818,
  A100 : #ffffff,
  A200 : #f2fff4,
  A400 : #bfffc9,
  A700 : #a6ffb4,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$abcdj-accent-palette: (
  50: #f3f3f3,
  100: #e0e0e0,
  200: #cccccc,
  300: #b8b8b8,
  400: #a8a8a8,
  500: #999999,
  600: #919191,
  700: #868686,
  800: #7c7c7c,
  900: #6b6b6b,
  A100: #fdeaea,
  A200: #f8bcbc,
  A400: #ff8181,
  A700: #ff6868,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000)
);

$light-text: #ffffff;
$dark-text: rgba(0, 0, 0, 0.87);
$abcdj-main-color: map-get($abcdj-palette, 500);
$abcdj-secondary-color: map-get($abcdj-palette, 300);
$abcdj-background-color: map-get($abcdj-palette, 100);

$abcdj-accent-color: map-get($abcdj-accent-palette, 500);
$abcdj-accent-background-color: map-get($abcdj-accent-palette, 100);
