@media (max-width: 767px) {
  body {
    height: calc(100vh - 1px);
    background-color: white;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .user-info {
    padding: 15px;
    text-align: right;
  }

  .top-distance {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 0px;
  }

  .grey-box-container {
    padding-bottom: 15px;
  }

  .nav>li {
    margin-right: 15px;
  }
}
